import React from "react";
import { CardBody, Button } from "reactstrap";
import "./secret-mod-controller.style.scss";
import { Tag } from "antd";
import { useSecretMod } from "../../hooks";
import { Loader } from "@/shared";

export const SecretModControllerWidget = () => {
  const { isActive, isLoading, turn } = useSecretMod();
  return (
    <div className="secret-mod-controller" style={{ marginBottom: 40 }}>
      <CardBody>
        <div className="header">
          <div className="custom-row">
            <h3>Прихований режим</h3>
            {isLoading ? (
              <Tag color="processing">Завантаження</Tag>
            ) : (
              <Tag color={isActive ? "magenta" : "green"}>
                {isActive ? "Активний" : "Не активний"}
              </Tag>
            )}
          </div>

          <Button color="primary" size="sm" onClick={turn}>
            {isActive ? "Вимкнути" : "Увімкнути"}
          </Button>
        </div>
      </CardBody>
    </div>
  );
};
