import React from "react";
import { createFullName, EUserStatus, IPListType } from "@/shared";
import moment from "moment";
import { voidColumn } from "@/components/TableGrid/configs/void-row.config";
import _ from "lodash";
import { Link } from "react-router-dom";

export const columnsConfig: any = [
  voidColumn,

  {
    name: "№",
    key: "id",
    width: 70,
    filterType: "search",
    resizable: false,
    sortable: true,
    filter: true,
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return "";
      return <div className="ellipsis">{row.id}</div>;
    },
  },
  {
    name: "ПІБ",
    key: "name",
    sortKey: "lastName",
    minWidth: 100,
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "search",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return "";
      return (
        <div className="ellipsis" style={{ width: "100%" }}>
          {row.user ? (
            <Link
              className="ellipsis"
              style={{ width: "100%" }}
              to={{
                pathname: `/user_detail/${row?.user?.id}`,
                state: { contactinfo: row },
              }}
            >
              <span className="full-name" style={{ color: "#000000" }}>
                {row?.user?.info
                  ? createFullName(
                      row.user?.info?.firstName,
                      row.user?.info?.middleName,
                      row.user?.info?.lastName
                    )
                  : "Невідомо"}
              </span>
            </Link>
          ) : (
            <span className="full-name" style={{ color: "#000000" }}>
              {"Невідомо"}
            </span>
          )}
        </div>
      );
    },
  },
  {
    name: "IP",
    key: "ip",
    minWidth: 80,
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "search",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return "";
      if (!row.ip) return <div className="ellipsis">{"Невідомо"}</div>;
      return <div className="ellipsis">{row.ip?.replace("::ffff:", "")}</div>;
    },
  },
  {
    name: "Статус користувача",
    key: "userStatus",
    minWidth: 150,
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "userStatus",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return "";
      if (!row.user) return <div className="ellipsis">{"Невідомо"}</div>;
      return (
        <div className="ellipsis">
          {row?.user?.status === EUserStatus.Active
            ? "Активний"
            : "Заблоковано"}
        </div>
      );
    },
  },
  {
    name: "Статус IP",
    key: "ipStatus",
    minWidth: 150,
    resizable: true,
    sortable: true,
    filter: true,
    filterType: "ipStatus",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      if (!row.ip) return <div className="ellipsis">{"Невідомо"}</div>;
      const ipTypesLabel = {
        [IPListType.Black]: "Заблоковано",
        [IPListType.White]: "Активний ",
      };
      return (
        <div className="ellipsis">
          <span>
            {row?.ipInfo?.listType
              ? ipTypesLabel[row?.ipInfo?.listType]
              : "Активний"}
          </span>
        </div>
      );
    },
  },
  {
    name: "Подія",
    key: "description",
    // minWidth: 300,
    filterType: "search",
    resizable: true,
    sortable: true,
    filter: true,
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      return <div className="ellipsis">{row.description}</div>;
    },
  },
  {
    name: "Дата",
    key: "date",
    sortKey: "createdAt",
    minWidth: 150,
    resizable: true,
    sortable: true,
    filter: true,
    sort_field: "date_sort",
    filterType: "datePicker",
    formatter: ({ row }) => {
      if (_.isEmpty(row)) return null;
      return (
        <div className="ellipsis">
          <span>{moment(row.createdAt).format("DD-MM-YY hh:mm:ss")}</span>
        </div>
      );
    },
  },
];

export const defaultActiveColumnsConfig = [
  "select-row",
  "id",
  "name",
  "ip",
  "userStatus",
  "ipStatus",
  "description",
  "date",
];
