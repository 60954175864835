import { notificApi } from "@/api/notifications/request";
import config from "@/config";
import { RouteEnum } from "@/containers/App/router";
import { IPushNotification, NotificationKeys } from "@/shared";
import { SelectChatId } from "@/store/chats";
import { simpleDispatch } from "@/store/store-helpers";
import OneSignal from "react-onesignal";

export const needRedirect = {
  to: null,
  payload: null,
};

const saveNeedRedirect = ({ to, payload }) => {
  needRedirect.to = to;
  needRedirect.payload = payload;
};

export const notificationActions = (
  notification: IPushNotification
  // tabIndex?: number
) => ({
  [NotificationKeys.NEW_MESSAGE]: {
    redirect: () => {
      simpleDispatch(
        new SelectChatId({ id: Number(notification.data.chatId) })
      );
      saveNeedRedirect({
        to: `/chats?id=${notification.data.chatId}`,
        payload: null,
      });
    },
  },
  [NotificationKeys.NEW_CHAT_MEMBER]: {
    redirect: () => {
      simpleDispatch(
        new SelectChatId({ id: Number(notification.data.chatId) })
      );
      saveNeedRedirect({
        to: `/chats?id=${notification.data.chatId}`,
        payload: null,
      });
    },
  },
  [NotificationKeys.NEW_TASK]: {
    redirect: () => {
      saveNeedRedirect({
        to: RouteEnum.Tasks,
        payload: null,
      });
    },
  },
  [NotificationKeys.NEW_TASK_COMMENT]: {
    redirect: () => {
      saveNeedRedirect({
        to: RouteEnum.Tasks,
        payload: {
          type: NotificationKeys.NEW_TASK_COMMENT,
          taskId: notification.data.taskId,
        },
      });
    },
  },
  [NotificationKeys.NEW_TASK_FILE]: {
    redirect: () => {
      saveNeedRedirect({
        to: RouteEnum.Tasks,
        payload: {
          type: NotificationKeys.NEW_TASK_FILE,
          taskId: notification.data.taskId,
        },
      });
    },
  },
  [NotificationKeys.TODAY_BIRTHDAY]: {
    redirect: () => {
      saveNeedRedirect({
        to: RouteEnum.Contacts,
        payload: { soonBirthday: true },
      });
    },
  },
});

const onOpened = (openResult) => {
  try {
    const notif: IPushNotification = {
      id: 0,
      title: null,
      content: null,
      createDate: null,
      isRead: false,
      userId: null,
      data: openResult.data as any,
    };

    const action = notificationActions(notif)[
      notif?.data?.type as NotificationKeys
    ];

    action?.redirect();
  } catch (e) {
    console.log(e);
  }
};

const runOneSignal = async () => {
  try {
    await OneSignal.init({
      appId: config.oneSignalId,
      allowLocalhostAsSecureOrigin: false,
    });

    console.log("one signal is inited");

    const isPermitted = await OneSignal.getNotificationPermission(
      (complete) => {
        console.log("complete", complete);
      }
    );
    console.log("is notifications permitted", isPermitted);

    if (isPermitted !== "granted") await OneSignal.showNativePrompt();

    if (isPermitted) await OneSignal.setSubscription(true);

    const userId = await OneSignal.getUserId();

    if (userId)
      await notificApi.saveUserDevice({
        notificationUserId: userId,
        deviceUuid: userId,
      });
  } catch (e) {
    console.log("ONE SIGNAL RUNNING ERROR", e);
  }
};

OneSignal.on("subscriptionChange", async (isSubscribed) => {
  console.log("SUBSCRIPTION IS CHANGED", isSubscribed);
  await OneSignal.setSubscription(isSubscribed);
});

OneSignal.addListenerForNotificationOpened(onOpened);

export const notificationsService = {
  runOneSignal,
};
