import { useEffect, useState } from "react";
import { ISendTextMessage } from "@/api/chats-messages/request.interfaces";
import { chatMessagesService } from "@/services/domain";
import {
  createFullName,
  getLinksFromTxt,
  hasImageUrl,
  IChatMember,
  IChatMessage,
  isImgUrl,
  useEventsListener,
} from "@/shared";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getProfile } from "@/store/account";
import { appEvents } from "@/shared/events";
import { chatMessagesApi } from "@/api";
import { dataURLtoFile } from "@/containers/Profile/helper";
import moment from "moment";

interface IProps {
  chatMembers: IChatMember[];
  chatId: number;
  replyToMessage: IChatMessage;
  onSend: () => void;
}

export const useCreateTextMessage = ({
  chatMembers,
  chatId,
  replyToMessage,
  onSend,
}: IProps) => {
  const accountId = useSelector(getProfile);

  const [message, setMessage] = useState<string>("");
  const [isSending, setSending] = useState<boolean>(false);
  const [editedMessage, setEditedMessage] = useState<IChatMessage>(null);

  useEventsListener(
    "onPressEditmessage",
    ({ message }) => {
      setEditedMessage(message);
      setMessage(message.content?.message);
    },
    [setEditedMessage, setMessage]
  );
  const sendEditMessage = async () => {
    const dataSend = {
      newMessage: message,
      messageId: editedMessage?.id,
    };
    onSend();
    clearEditedMessage();
    await chatMessagesService.editTextMessage(dataSend);
  };
  const sendNewMessage = async () => {
    const data: ISendTextMessage = {
      chatId,
      message: message,
      replyToId: replyToMessage?.id,
    };
    onSend();
    setMessage(null);
    await chatMessagesService.sendTextMessage(data);
  };
  const sendMessage = async () => {
    if (!message || message.trim().length === 0) return;

    setSending(true);

    try {
      if (editedMessage) {
        sendEditMessage();
      } else {
        sendNewMessage();
      }
    } catch (error) {
      console.log("SEND MESSAGE ERROR :", error);
    } finally {
      setSending(false);
    }
  };

  const toImgDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const onSendCopyImg = async (fileUrl: string) => {
    setSending(true);
    const fileExt = fileUrl.split(".").pop();

    try {
      const dataUrl: any = await toImgDataURL(fileUrl);

      const ImgUrl = dataUrl.replace(
        "data:application/octet-stream",
        "data:image/png"
      );

      const fileStat = dataURLtoFile(
        ImgUrl,
        `${moment(new Date().toISOString()).format(
          "DD-MM-YYYY.HHmmss"
        )}.${fileExt.toLowerCase()}`
      );

      setTimeout(async () => {
        await chatMessagesApi.sendImageMessageReq({
          file: fileStat,
          chatId,
          fileName: fileStat.name,
        });

        navigator.clipboard.writeText("");
      }, 1000);
    } catch (err) {
      console.log({ err });
    }
    setSending(false);
  };

  const detectImgUrl = (msg: string) => {
    const urls = getLinksFromTxt(msg);

    if (urls?.length && isImgUrl(urls[0])) {
      const msgWithoutImgUrl = msg.replace(urls[0], "");

      appEvents.emit("chatSendImgModal", {
        isShow: true,
        url: urls[0],
        onSend: () => onSendCopyImg(urls[0]),
      });

      setMessage(msgWithoutImgUrl);
    }
  };

  useEffect(() => {
    if (message) detectImgUrl(message);
  }, [message]);

  const usersCanMention = _.filter(
    chatMembers,
    (member) => !member.isDeleted && member?.userId !== accountId.id
  );

  const allItems = usersCanMention.map((it) => {
    const { firstName, lastName, avatarUrl } = it?.user;

    const name = createFullName(firstName, lastName);
    return {
      id: it.userId,
      name,
      avatar: hasImageUrl(avatarUrl, name),
    };
  });

  const clearEditedMessage = () => {
    console.log("clear edit");
    setEditedMessage(null);
    setMessage("");
  };
  return {
    suggestionItems: allItems,
    message,
    setMessage,
    isSending,
    sendMessage,
    editedMessage,
    clearEditedMessage,
  };
};
